
export interface IFeedRule {
	allowMatch: boolean;
	titlePattern: string;
}

export interface IFeed {
	allowUnmatched: boolean;
	name: string;
	rules: IFeedRule[];
	url: string;
}

export interface IFeedUrls {
	fetch: string;
	fetchJson: string;
	list: string;
	delete: string;
	save: string;
}

export interface IFilteredItem {
	link: string;
	passingFilters: boolean;
	title: string;
}

export interface IFilteredFeed {
	items: IFilteredItem[];
}

export type RssItemMode = "all" | "blocked" | "passing";

export function getRuleIndex(rules: IFeedRule[], rule: IFeedRule) {
	return rules.findIndex(r => r.titlePattern === rule.titlePattern);
}

export function filterItems(items: IFilteredItem[], itemMode: RssItemMode) {
	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
	switch (itemMode) {
		case "blocked":
			return items.filter(i => !i.passingFilters);
		case "passing":
			return items.filter(i => i.passingFilters);
		default:
			return items;
	}
}

export function addRule(rules: IFeedRule[], titlePattern: string, allowMatch: boolean): IFeedRule[] {
	const updatedRules = rules.filter(r => r.titlePattern !== titlePattern);
	updatedRules.push({ allowMatch, titlePattern });

	return updatedRules;
}

export function removeRule(rules: IFeedRule[], rule: IFeedRule): IFeedRule[] {
	return rules.filter(r => r.titlePattern !== rule.titlePattern);
}

export function moveRule(rules: IFeedRule[], rule: IFeedRule, offset: number): IFeedRule[] {
	const item = rules.find(r => r.titlePattern === rule.titlePattern);
	if (item == null) {
		return rules;
	}

	const index = getRuleIndex(rules, rule);
	if (index + offset < 0 || index + offset > rules.length) {
		return rules;
	}

	rules = [...rules];
	rules.splice(index, 1);
	rules.splice(index + offset, 0, item);

	return rules;
}
