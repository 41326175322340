import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IFeed, IFeedRule, IFeedUrls, IFilteredFeed, addRule, moveRule, removeRule } from "./Model";
import { rssFetch } from "./RssFetch";
import { AppThunk, IActionCreators } from "./Store";

export interface IRssState {
	dirty: boolean;
	error: string | null;
	feed: IFeed;
	filteredFeed: IFilteredFeed;
	urls: Readonly<IFeedUrls>;
}

export interface IRssStateContainer {
	rss: IRssState;
}

export function createRssSlice(feed: IFeed, urls: Readonly<IFeedUrls>) {
	const initialState: IRssState = {
		dirty: false,
		error: null,
		feed,
		filteredFeed: { items: [] },
		urls
	};

	return createSlice({
		initialState,
		name: "rss",
		reducers: {
			addRule: (state, { payload }: PayloadAction<{ titlePattern: string; allowMatch: boolean }>) => {
				state.dirty = true;
				state.feed.rules = addRule(state.feed.rules, payload.titlePattern, payload.allowMatch);
			},
			changesSaved: state => {
				state.dirty = false;
			},
			feedFetched: (state, { payload }: PayloadAction<IFilteredFeed>) => {
				state.filteredFeed = payload;
			},
			moveRule: (state, { payload }: PayloadAction<{ offset: number; rule: IFeedRule }>) => {
				state.dirty = true;
				state.feed.rules = moveRule(state.feed.rules, payload.rule, payload.offset);
			},
			removeRule: (state, { payload }: PayloadAction<IFeedRule>) => {
				state.dirty = true;
				state.feed.rules = removeRule(state.feed.rules, payload);
			},
			showError: (state, { payload }: PayloadAction<string | null>) => {
				state.error = payload;
			},
			updateFeed: (state, { payload }: PayloadAction<Partial<Pick<IFeed, "allowUnmatched" | "name" | "url">>>) => {
				state.feed = { ...state.feed, ...payload };
			}
		}
	});
}

export type RssActionCreators = ReturnType<typeof createRssSlice>["actions"];

export type RssReducer = ReturnType<typeof createRssSlice>["reducer"];

export function deleteFeed(actions: IActionCreators): AppThunk {
	return async (dispatch, getState) => {
		const state = getState();

		try {
			dispatch(actions.rss.showError(null));

			const response = await rssFetch(state.rss.urls.delete, { method: "DELETE" });

			if (response.ok) {
				document.location.href = state.rss.urls.list;
			} else {
				console.debug(response);
				dispatch(actions.rss.showError("Could not delete feed"));
			}
		} catch (error: unknown) {
			console.debug(error);
			dispatch(actions.rss.showError("Could not delete feed"));
		}
	};
}

export function refreshFeed(actions: IActionCreators): AppThunk {
	return async (dispatch, getState) => {
		const state = getState();
		const options: RequestInit = {
			method: "GET"
		};

		try {
			dispatch(actions.rss.showError(null));

			const response = await rssFetch(state.rss.urls.fetchJson, options);

			if (response.ok) {
				dispatch(actions.rss.feedFetched(await response.json() as IFilteredFeed));
			} else {
				console.debug(response);
				dispatch(actions.rss.showError("Could not refresh feed"));
			}
		} catch (error: unknown) {
			console.debug(error);
			dispatch(actions.rss.showError("Could not refresh feed"));
		}
	};
}

export function saveChanges(actions: IActionCreators): AppThunk {
	return async (dispatch, getState) => {
		const state = getState();
		const options: RequestInit = {
			body: JSON.stringify(state.rss.feed)
		};

		try {
			dispatch(actions.rss.showError(null));

			const response = await rssFetch(state.rss.urls.save, options);
			if (response.ok) {
				dispatch(actions.rss.changesSaved());
			} else {
				console.debug(response);
				dispatch(actions.rss.showError("Could not save feed"));
			}
		} catch (error: unknown) {
			console.debug(error);
			dispatch(actions.rss.showError("Could not save feed"));
		}

		await dispatch(refreshFeed(actions));
	};
}
