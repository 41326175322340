import { useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider, shallowEqual } from "react-redux";

import { Alert } from "Components/Alert";
import { Input } from "Components/Input";
import { UrlInput } from "Components/UrlInput";

import { AddRuleInput } from "./AddRuleInput";
import { IFeed, IFeedRule, IFeedUrls, RssItemMode, filterItems, getRuleIndex } from "./Model";
import { createRssSlice, deleteFeed, refreshFeed, saveChanges } from "./RssStore";
import { IActionCreators, createStore, setupActions, useActions, useStateSelector } from "./Store";

function ActionButtons(props: { rule: IFeedRule }) {
	const [dispatch, actions] = useActions();

	return (
		<button className="btn btn-link" onClick={() => dispatch(actions.rss.removeRule(props.rule))}>
			<span className="bi bi-trash-fill text-danger"></span>
		</button>
	);
}

function PositionButtons(props: { rule: IFeedRule; index: number }) {
	const [dispatch, actions] = useActions();
	const { rules } = useStateSelector(state => ({
		rules: state.rss.feed.rules
	}), shallowEqual);

	return (
		<div className="btn-group">
			<button className="btn btn-link text-primary" disabled={props.index === rules.length - 1} onClick={() => dispatch(actions.rss.moveRule({ offset: 1, rule: props.rule }))}>
				<span className="bi bi-caret-down-fill"></span>
			</button>
			<button className="btn btn-link text-primary" disabled={props.index === 0} onClick={() => dispatch(actions.rss.moveRule({ offset: -1, rule: props.rule }))}>
				<span className="bi bi-caret-up-fill"></span>
			</button>
		</div>
	);
}

function Rules() {
	const [dispatch, actions] = useActions();
	const { allowUnmatched, rules } = useStateSelector(state => ({
		allowUnmatched: state.rss.feed.allowUnmatched,
		rules: state.rss.feed.rules
	}), shallowEqual);

	function focusRule(ruleIndex: number) {
		if (ruleIndex < 0 || ruleIndex > rules.length) {
			return;
		}

		setTimeout(() => {
			const element = document.querySelector<HTMLElement>(`.rule[data-index="${ruleIndex}"]`);
			if (element) {
				element.focus();
			}
		}, 0);
	}

	function keyPressedOnRule(rule: IFeedRule, e: React.KeyboardEvent<unknown>) {
		// shift + up arrow
		if (e.shiftKey && e.keyCode === 38) {
			dispatch(actions.rss.moveRule({ offset: -1, rule }));
			focusRule(getRuleIndex(rules, rule));
			e.preventDefault();

			return;
		}

		// up arrow
		if (e.keyCode === 38) {
			focusRule(getRuleIndex(rules, rule) - 1);
			e.preventDefault();

			return;
		}

		// shift + down arrow
		if (e.shiftKey && e.keyCode === 40) {
			dispatch(actions.rss.moveRule({ offset: 1, rule }));
			focusRule(getRuleIndex(rules, rule));
			e.preventDefault();

			return;
		}

		// down arrow
		if (e.keyCode === 40) {
			focusRule(getRuleIndex(rules, rule) + 1);
			e.preventDefault();

			return;
		}

		// delete
		if (e.keyCode === 46) {
			dispatch(actions.rss.removeRule(rule));
			e.preventDefault();

			return;
		}
	}

	return (
		<div>
			<h2>Filter</h2>
			<div className="mb-3">
				<div className="form-check">
					<input
						id="allow-unmatched"
						type="checkbox"
						className="form-check-input"
						checked={!allowUnmatched}
						onChange={() => dispatch(actions.rss.updateFeed({ allowUnmatched: !allowUnmatched }))}
					/>
					<label className="form-label" htmlFor="allow-unmatched">Hide items that don&apos;t match any rules</label>
				</div>
			</div>
			<AddRuleInput onAddRule={(pattern, allowMatch) => dispatch(actions.rss.addRule({ allowMatch, titlePattern: pattern }))} />
			<h3>Active rules</h3>
			<table className="table table-borderless table-hover">
				<tbody>
					{rules.map((rule, i) =>
						<tr key={i} className="rule" data-index={i} tabIndex={0} onKeyDown={e => keyPressedOnRule(rule, e)}>
							<td className="p-0">
								<PositionButtons rule={rule} index={i} />
							</td>
							<td className={(rule.allowMatch ? "text-success" : "text-danger") + " w-100"}>
								<span className="mx-2">
									{rule.allowMatch ? <span className="bi-check-lg"></span> : <span className="bi bi-x-lg"></span>}
								</span>
								&quot;{rule.titlePattern}&quot;
							</td>
							<td className="p-0">
								<ActionButtons rule={rule} />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

function Items() {
	const { items } = useStateSelector(state => ({
		items: state.rss.filteredFeed.items
	}), shallowEqual);

	const [itemMode, setItemMode] = useState<RssItemMode>("all");

	return (
		<div>
			<div className="btn-group float-end">
				<button className={"btn btn-tinted " + (itemMode === "all" ? "active" : "")}
					onClick={() => setItemMode("all")}
				>
					Show all
				</button>
				<button className={"btn btn-tinted btn-tinted-success " + (itemMode === "passing" ? "active" : "")}
					onClick={() => setItemMode("passing")}
				>
					<span className="bi bi-check-lg me-2"></span> Passing
				</button>
				<button className={"btn btn-tinted btn-tinted-danger " + (itemMode === "blocked" ? "active" : "")}
					onClick={() => setItemMode("blocked")}
				>
					<span className="bi bi-x-lg me-2"></span> Blocked
				</button>
			</div>
			<h2>Posts</h2>
			<table className="table table-borderless table-hover table-sm plain-links">
				<thead>
					<tr>
						<th>Title</th>
						<th style={{ width: 0 }}></th>
					</tr>
				</thead>
				<tbody>
					{filterItems(items, itemMode).map((item, i) => (
						<tr key={i}>
							<td>
								<a className={item.passingFilters ? "" : "text-muted"} href={item.link}>{item.title}</a>
							</td>
							<td className="text-center">
								<span className={"bi " + (item.passingFilters ? "bi-check-lg text-success" : "bi-x-lg text-danger")}></span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

function RssApp() {
	const [dispatch, actions] = useActions();
	const { dirty, error, feedName, feedUrl, urls } = useStateSelector(state => ({
		dirty: state.rss.dirty,
		error: state.rss.error,
		feedName: state.rss.feed.name,
		feedUrl: state.rss.feed.url,
		urls: state.rss.urls
	}), shallowEqual);

	const [confirmDelete, setConfirmDelete] = useState(false);

	return (
		<div className="rss content-layout t-edit-rss">
			<div className="d-lg-flex justify-content-between align-items-center">
				<div>
					<h1>{feedName}</h1>
					<p>
						<a href={urls.fetch}>
							<span className="bi bi-rss-fill me-2"></span>Filtered feed
						</a>
					</p>
				</div>
				<div>
					<div className="btn-group w-100">
						<a href={urls.list} className="btn btn-tinted">
							<span className="bi bi-chevron-left me-2"></span> Back<span className="d-none d-md-inline"> to list</span>
						</a>
						<button className="btn btn-tinted btn-tinted-danger" onClick={() => setConfirmDelete(true)}>
							<span className="bi bi-trash-fill me-2"></span> Delete
						</button>
						<button className="btn btn-tinted btn-tinted-success" onClick={() => { void dispatch(refreshFeed(actions)); }}>
							<span className="bi bi-arrow-repeat me-2"></span> Refresh
						</button>
						<button className="btn btn-success" disabled={!dirty} onClick={() => { void dispatch(saveChanges(actions)); }}>
							<span className="bi bi-check-lg me-2"></span> Save
						</button>
					</div>
				</div>
			</div>
			<Alert type="danger" visible={error != null}>
				<strong>Error</strong> {error}
			</Alert>
			<Alert type="danger" visible={confirmDelete}>
				<div className="d-flex align-items-center">
					<span className="flex-grow-1">
						Are you sure you want to <strong>delete this feed</strong>?
					</span>
					<div className="btn-group">
						<button className="btn btn-danger" onClick={() => { setConfirmDelete(false); void dispatch(deleteFeed(actions)); }}>Delete feed</button>
						<button className="btn btn-secondary" onClick={() => setConfirmDelete(false)}>Keep feed</button>
					</div>
				</div>
			</Alert>
			<div className="row">
				<div className="col-md-6">
					<Input id="feed-name" type="text"
						label="Name"
						value={feedName}
						onChange={value => dispatch(actions.rss.updateFeed({ name: value }))}
					/>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<UrlInput id="feed-url"
							label="URL"
							value={feedUrl}
							onChange={value => dispatch(actions.rss.updateFeed({ url: value }))}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
					<Rules />
				</div>
				<div className="col-md-7">
					<Items />
				</div>
			</div>
		</div>
	);
}

interface IEditFeedData {
	feed: IFeed;
	feedUrls: IFeedUrls;
}

const rssElement = document.getElementById("edit-rss-feed");
const rssDataElement = document.getElementById("edit-rss-feed-data");
if (rssElement != null && rssDataElement != null) {
	const data = JSON.parse(rssDataElement.innerHTML) as IEditFeedData;
	const rssSlice = createRssSlice(data.feed, data.feedUrls);
	const store = createStore({
		rss: rssSlice.reducer
	});

	const actions: IActionCreators = {
		rss: rssSlice.actions
	};

	const ActionProvider = setupActions(actions);
	const root = createRoot(rssElement);

	root.render(
		<Provider store={store}>
			<ActionProvider value={actions}>
				<RssApp />
			</ActionProvider>
		</Provider>
	);

	void store.dispatch(refreshFeed(actions));
}
