import { useState } from "react";

interface IAddRuleInputProps {
	onAddRule: (titlePattern: string, allowMatch: boolean) => void;
}

export function AddRuleInput(props: IAddRuleInputProps) {
	const [pattern, setPattern] = useState("");

	return (
		<div className="mb-3">
			<label className="form-label" htmlFor="addRule">Add rule</label>
			<div className="input-group">
				<input id="addRule" type="text" className="form-control" placeholder="Regex" onChange={e => setPattern(e.currentTarget.value)} />
				<button className="btn btn-tinted btn-tinted-success" disabled={!pattern} onClick={() => props.onAddRule(pattern, true)}>
					<span className="bi bi-check-lg me-2"></span> Allow
				</button>
				<button className="btn btn-tinted btn-tinted-danger" disabled={!pattern} onClick={() => props.onAddRule(pattern, false)}>
					<span className="bi bi-x-lg me-2"></span> Block
				</button>
			</div>
		</div>
	);
}
