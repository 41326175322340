export function Alert(props: { children?: React.ReactNode; type: "danger" | "info" | "success" | "warning"; visible?: boolean }) {
	if (props.visible === false) {
		return null;
	}

	return (
		<div className={"alert alert-" + props.type} role="alert">
			{props.children}
		</div>
	);
}
