import { createTopbar } from "Common/Topbar";

const topbar = createTopbar({
	barColors: [
		[0, "rgba(239, 153, 77, 1)"],
		[1, "rgba(240, 173, 78, 1)"]
	]
});

function getXsrfToken() {
	const element = document.querySelector<HTMLInputElement>("input[name=__RequestVerificationToken]");

	if (element == null) {
		throw new Error("Could not find request verification token");
	}

	return element.value;
}

export async function rssFetch(url: string, options?: RequestInit) {
	const headers = new Headers();
	headers.append("Accept", "application/json");
	headers.append("Content-Type", "application/json");
	headers.append("X-XSRF-TOKEN", getXsrfToken());

	const fetchOptions: RequestInit = {
		credentials: "same-origin",
		headers,
		method: "POST",
		...options
	};

	try {
		topbar.show();

		return await fetch(url, fetchOptions);
	} finally {
		topbar.hide();
	}
}
