import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { createContext, useContext } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { RssActionCreators, RssReducer } from "./RssStore";

export interface IActionCreators {
	rss: RssActionCreators;
}

export function createStore(reducer: { rss: RssReducer }) {
	return configureStore({
		reducer
	});
}

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];

export type AppState = ReturnType<ReturnType<typeof createStore>["getState"]>;

export type AppThunk = ThunkAction<Promise<void>, AppState, undefined, Action>;

let actionContext: React.Context<IActionCreators> | null = null;

export function setupActions(actions: IActionCreators) {
	actionContext = createContext(actions);

	return actionContext.Provider;
}

export function useActions() {
	if (actionContext == null) {
		throw new Error("Action context not initialized");
	}

	return [useDispatch<AppDispatch>(), useContext(actionContext)] as const;
}

export const useStateSelector: TypedUseSelectorHook<AppState> = useSelector;
